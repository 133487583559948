<template>
  <div class="container">
    <h1>총판관리</h1>
    <div class="month-picker-container">
      <span class="prev-btn" @click="changeMonth(1)">◀︎</span>
      <span class="year-month">{{ dateString }}</span>
      <span class="next-btn" @click="changeMonth(-1)">▶︎</span>
    </div>
    <div class="my-4">
      <select
        v-if="this.$store.state.user ? this.$store.state.user.level >= 6 : false"
        v-model="selectedRecommendCode"
        @change="getUsers"
        class="mr-3"
      >
        <option :key="recommendCode.id" v-for="recommendCode in recommendCodes" v-bind:value="recommendCode.code">
          {{ recommendCode.code }}
        </option>
      </select>
      <select v-model="selectedUserId">
        <option :key="user.id" v-for="user in users" v-bind:value="user.id">
          {{ user.username }}
        </option>
      </select>
    </div>
    <pulse-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size"></pulse-loader>
    <table class="border-table mt-4" v-if="items.length > 0">
      <thead>
        <tr>
          <td width="120px">기준일자</td>
          <td width="120px">이름</td>
          <td>발송건수</td>
          <td>성공건수</td>
          <td>실패건수</td>
          <td>대기건수</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="item in items">
          <td>
            {{ item.date | dateShortFormat }}
          </td>
          <td>
            {{ item.username }}
          </td>
          <td>
            {{ item.totalCount | numberFormat }}
          </td>
          <td>
            {{ item.successCount | numberFormat }}
          </td>
          <td>
            {{ item.failedCount | numberFormat }}
          </td>
          <td>
            {{ item.waitCount | numberFormat }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>합계</td>
          <td></td>
          <td>{{ totalInfo.totalCount | numberFormat }}</td>
          <td>{{ totalInfo.successCount | numberFormat }}</td>
          <td>{{ totalInfo.failedCount | numberFormat }}</td>
          <td>{{ totalInfo.waitCount | numberFormat }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
import moment from 'moment-timezone'
import UserService from '@/services/UserService'
import RecommendCodeService from '@/services/RecommendCodeService'
import SaleService from '@/services/SaleService'
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'

import { mapGetters } from 'vuex'

export default {
  name: 'Distributor',
  components: {
    PulseLoader,
  },
  created() {},
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')

    this.dateString = this.date.format('YYYY.MM')
    this.update()
  },
  data() {
    return {
      isLoading: true,
      loaderStyle: {
        color: '#ccc',
        size: '8px',
      },
      users: [],
      date: moment(),
      dateString: '',
      recommendCodes: [],
      selectedRecommendCode: '전체',
      selectedUserId: null,
      items: [],
      totalInfo: {},
    }
  },
  methods: {
    update() {
      var t = this

      if (!this.user) {
        return
      }

      if (this.user.recommendCode.length == 0 && this.user.level == 6) {
        alert('추천코드가 설정되지 않은 총판입니다')
        this.$router.go(-1)
        return
      }

      var isAdmin = this.user.level >= 8
      var isSales = this.user.level >= 6 && this.user.level < 8

      this.getRecommendCodes(function() {
        if (isAdmin) {
          var recommendCodes = t.user.recommendCode.split(',')
          t.selectedRecommendCode = recommendCodes[0]
        } else if (isSales) {
          t.selectedRecommendCode = '전체'
        }

        t.getUsers()
      })
    },
    changeMonth(offset) {
      this.date = this.date.subtract(offset, 'months')
      this.dateString = this.date.format('YYYY.MM')
      this.getDistributorList()
    },
    async getRecommendCodes(callback) {
      const response = await RecommendCodeService.list({
        limit: 999,
      })

      if (response.status == 200) {
        this.recommendCodes = [
          {
            id: 0,
            code: '전체',
          },
        ].concat(response.data.list)

        callback()
      }
    },
    async getUsers() {
      this.users = []

      const response = await UserService.getUsers({
        recommendCode: this.selectedRecommendCode,
        limit: 1000,
        order: ['username'],
      })
      if (response.status == 200) {
        this.users = [
          {
            id: 0,
            username: '전체',
          },
        ].concat(response.data.list)

        this.selectedUserId = 0
        this.getDistributorList()
      }
    },
    async getDistributorList() {
      this.items = []
      this.isLoading = true
      var data = {
        page: this.currentPage,
        year: this.date.format('YYYY'),
        month: this.date.format('MM'),
        recommendCode: this.selectedRecommendCode,
      }
      if (this.selectedUserId != 0) {
        data.userId = this.selectedUserId
      }

      const response = await SaleService.distributorList(data)
      this.isLoading = false
      if (response.status == 200) {
        this.items = response.data.list
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    user: function(newUser) {
      if (newUser) {
        this.update()
        this.selectedRecommendCode = newUser.recommendCode
      }
    },
    currentPage: function() {
      this.getDistributorList()
    },
    selectedUserId: function() {
      this.getDistributorList()
    },
    totalAmount: function() {},
    items: function() {
      var totalInfo = {
        totalCount: 0,
        successCount: 0,
        failedCount: 0,
        waitCount: 0,
        totalAmount: 0,
      }
      for (var item of this.items) {
        var totalCount = parseInt(item['totalCount'])
        if (!isNaN(totalCount)) {
          totalInfo.totalCount += totalCount
        }

        var successCount = parseInt(item['successCount'])
        if (!isNaN(successCount)) {
          totalInfo.successCount += successCount
          totalInfo.totalAmount += parseFloat(((item.smsFee / 1.1 - 16) / 2) * successCount)
        }

        var failedCount = parseInt(item['failedCount'])
        if (!isNaN(failedCount)) {
          totalInfo.failedCount += failedCount
        }

        var waitCount = parseInt(item['waitCount'])
        if (!isNaN(waitCount)) {
          totalInfo.waitCount += waitCount
        }
      }
      this.totalInfo = totalInfo
    },
  },
}
</script>
<style scoped>
.month-picker-container {
  display: block;
  text-align: center;
}

.month-picker-container > span {
  display: inline-block;
}

.year-month {
  font-size: 20px;
}

.prev-btn,
.next-btn {
  padding: 20px;
  margin: 20px;
  font-size: 15px;
  cursor: pointer;
}

.border-table td {
  text-align: right;
  padding: 10px;
}

.border-table tr > td:first-of-type {
  text-align: left;
}

.border-table tr > td:nth-of-type(2) {
  text-align: left;
}
</style>
